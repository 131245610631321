import { Fragment } from "react"
import { Typography } from "@mui/material"

export const UseRefreshTokenTooltip = () => <Fragment>
  <Typography color="inherit">How id/access tokens should be
    reloaded</Typography>
  <em>Token Tester provides</em> <b>2 options </b>
  <u>to fetch fresh access and id tokens:</u>
  <ul>
    <li>
      <b>Refresh tokens </b>
      - uses refresh tokens (need to be enabled for your SSO application)<br/>
      <b><u>IMPORTANT:</u></b>&nbsp;
      There is no way to use
      standard <b>audience</b> and <b>scope</b> parameters(initial values
      are used)
      via refresh token flow initiated by SDK.<br/>
      As workaround, NXP SSO supports custom
      parameter <b><u>refresh_scope</u></b>
      (refer to <a target="_blank"
        href="https://github.com/auth0/auth0-spa-js/issues/896#issuecomment-1103647320"
        rel="noreferrer">
      Question: refresh token call and custom rules</a>)
      to pass scopes you need for
      entitlements(like &#34;openid profile BMT PINK SCRIP&#34;)<br/>
      Audience value can not be changed.
    </li>
    <li><b>Authorization code </b>
      - requests new authorization code (via
      /authorize endpoint) and requests fresh tokens (via /token
      endpoint)<br/>
      This flow supports providing custom audience and scope.
      <br/><b><u>IMPORTANT:</u></b> you might have issues in Firefox and Safari(if you app does not use
      norstella.com domain) and in incognito mode (in this case you need to switch to refresh tokens).
    </li>
  </ul>
  <b><u>IMPORTANT:</u></b> Token tester sets value in local storage which
  is
  passed to Auth0Provider, so page needs to be reloaded for setting to be
  applied
</Fragment>
