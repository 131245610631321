import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'

const defaultTokens = {
  idToken: '',
  accessToken: '',
  expiresIn: undefined
}

export const useDecodedTokens = () => {
  const [tokens, setTokens] = useState(defaultTokens)
  const [isLoading, setIsLoading] = useState(false)
  const { getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated } = useAuth0()
  const reloadTokens = useCallback(async (options = null) => {
    const currentOptions = options || { detailedResponse: true }
    if (isAuthenticated) {
      setIsLoading(true)
      let loadedTokens = {}
      try {
        // try to load access token silently
        loadedTokens = await getAccessTokenSilently(currentOptions)
      } catch (error) {
        try {
          // fallback to get access token with popup that allows to give user consent if required
          await getAccessTokenWithPopup(currentOptions)
        } catch (error) {
          // things can not be handled, show error message and reset tokens
          loadedTokens = undefined
          return
        }
        loadedTokens = await getAccessTokenSilently(currentOptions)
      } finally {
        const tokens = loadedTokens ? {
          idToken: jwt_decode(loadedTokens.id_token),
          accessToken: jwt_decode(loadedTokens.access_token),
          expiresIn: loadedTokens.expires_in,
          loadedTokens
        } : defaultTokens

        setTokens(tokens)
        setIsLoading(false)
      }
    }
  }, [getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated])

  useEffect(() => {
    reloadTokens().catch(error => console.error(error))
    // NOTE: empty dependencies to reload tokens only initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [tokens, reloadTokens, isLoading]
}
