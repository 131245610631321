import loading from "../assets/loading.svg"
import PropTypes from "prop-types"

const Loading = ({ className = "spinner" }) => (
  <div className={className}>
    <img src={loading} alt="Loading" />
  </div>
)

Loading.propTypes = {
  className: PropTypes.string
}

export default Loading
