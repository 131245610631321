export function getConfig() {
  return {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    management_audience: process.env.REACT_APP_AUTH0_MANAGEMENT_API_AUDIENCE,
    apiOrigin: process.env.REACT_APP_API_ORIGIN || "http://localhost:3001",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    ssoUrl: process.env.REACT_APP_SSO_URL,
    scope: 'user_metadata profile email BMT sample-app:user sample-app:admin nxp-sso:token-tester nxp-sso:user'
  }
}
