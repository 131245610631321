import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import { Button, DialogContentText } from "@mui/material"
import DialogActions from "@mui/material/DialogActions"
import Dialog from "@mui/material/Dialog"
import PropTypes from "prop-types"

export const TokenTesterRefreshAlert = ({ open, setOpen }) => {
  const handleClose = (reload) => {
    if(reload){
      window.location.href = '/'
    }
    setOpen(false)
  }

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      IMPORTANT: Page Reload is required
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        You are enabling/disabling refresh tokens usage.<br/>
        This operation requires page refresh for changes to be applied.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={() => handleClose(true)}>Refresh Now</Button>
      <Button variant="contained" onClick={() => handleClose(false)} autoFocus>Refresh Later</Button>
    </DialogActions>
  </Dialog>
}

TokenTesterRefreshAlert.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}
