import { useApiCallback } from "./useApiCallback"
import { useMemo } from "react"

export const useAppMetadata = () => {
  const { data, error } = useApiCallback('/metadata')

  return useMemo(() => ({
    metadata: data,
    error
  }), [data, error] )
}
