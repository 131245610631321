import { Fragment } from "react"
import { Typography } from "@mui/material"

export const UseTokenCacheTooltip = () => <Fragment>
  <Typography color="inherit">Token cache usage</Typography>
  Controls cache usage for loading tokens, if enabled token will be loaded from cache if it is not
  expired. If token in cache is expired then new one is loaded.<br/>
  <b><u>IMPORTANT:</u></b>&nbsp; SDK uses <b>audience</b> and <b>scope</b> as keys for cache<br/>
  If you have <b>Use Refresh Tokens enabled</b> and <b>Use Tokens Cache enabled</b> and
  pass <b>refresh_scope</b> with different
  from originally requested via login value then you will not get newly requested scopes.
</Fragment>
