import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { Auth0Provider } from "@auth0/auth0-react"
import history from "./utils/history"
import { getConfig } from "./config"
import { AppContextProvider } from "./components/AppContextProvider"

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  )
}

const { domain, clientId, audience, scope } = getConfig()

const providerConfig = {
  domain,
  clientId,
  authorizationParams: {
    ...(audience ? { audience } : null),
    ...(scope ? { scope } : null),
    refresh_scope: scope,
    redirect_uri: window.location.origin
  },
  useCookiesForTransactions: true,
  useRefreshTokens: localStorage.getItem('useRefreshTokens') === "true",
  onRedirectCallback
}

const SSOSampleApp = () => {
  return <Auth0Provider
    domain={providerConfig.domain}
    clientId={providerConfig.clientId}
    authorizationParams={providerConfig.authorizationParams}
    useRefreshTokens={providerConfig.useRefreshTokens}
    onRedirectCallback={providerConfig.onRedirectCallback}
    useCookiesForTransactions={providerConfig.useCookiesForTransactions}
  >
    <AppContextProvider>
      <App/>
    </AppContextProvider>
  </Auth0Provider>
}

ReactDOM.render(<SSOSampleApp/>, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
