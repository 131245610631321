import { useCallback, useState } from 'react'

export const useForm = initialFormData => {
  const [formData, setFormData] = useState(initialFormData)

  const handleFormEdit = useCallback((fieldName, fieldValue) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [fieldName]: fieldValue
      }
    })
  }, [])

  return [formData, handleFormEdit]
}
