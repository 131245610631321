import { useState } from "react"
import { NavLink as RouterNavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"

import { useAppContext } from "./AppContextProvider"
import { getConfig } from "../config"
import { TokenTester } from "./tokenTester/TokenTester"
import { AuthorizationGuard } from "./AuthorizationGuard"

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isTokenTesterOpen, setIsTokenTesterOpen] = useState(false)
  const { user, routes, logout, isAuthenticated, loginWithRedirect } = useAppContext()
  const toggle = () => setIsOpen(!isOpen)

  const logoutWithRedirect = async () =>
    await logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })

  const renderApiNavItems = () => routes.map(route =>
    <NavItem key={route.path}>
      <NavLink
        tag={RouterNavLink}
        to={route.path}
        exact
        activeClassName="router-link-exact-active"
      >
        {route.name}
      </NavLink>
    </NavItem>
  )

  const navigateToProfile = () => {
    const source = encodeURIComponent(window.location.href)
    const { ssoUrl } = getConfig()
    window.location.replace(`${ssoUrl}?source=${source}`)
  }

  return (
    <div className="nav-container">
      <TokenTester isOpen={isTokenTesterOpen} onClose={() => setIsTokenTesterOpen(false)}/>
      <Navbar color="light" light expand="md">
        <Container className="app-nav-container">
          <img src={'./logo-192x192.png'} alt="NXP-SSO logo" className="app-logo"/>
          <NavbarToggler onClick={toggle}/>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                  exact
                  activeClassName="router-link-exact-active"
                >
                  Home
                </NavLink>
              </NavItem>
              {renderApiNavItems()}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                  <Button
                    id="qsTrialLoginBtn"
                    color="secondary"
                    className="btn-margin ml-3"
                    onClick={() => loginWithRedirect({
                      authorizationParams: {
                        screen_hint: 'signup', 'ext-trialProductCode': 'test123-productCode-NXP-Sample'
                      }
                    })}
                  >
                    Trial Sign up
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="#"
                      onClick={navigateToProfile}
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3"/> Profile
                    </DropdownItem>
                    <AuthorizationGuard scopes={['nxp-sso:token-tester']}>
                      <DropdownItem
                        tag={RouterNavLink}
                        to="#"
                        onClick={() => setIsTokenTesterOpen(true)}
                        className="dropdown-tokenTester"
                        activeClassName="router-link-exact-active"
                      >
                        <FontAwesomeIcon icon="key" className="mr-3"/> Token Tester
                      </DropdownItem>
                    </AuthorizationGuard>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3"/> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3"/>
                  <RouterNavLink
                    to="#" target="_blank"
                    onClick={navigateToProfile}
                    activeClassName="router-link-exact-active"
                  >
                    Profile
                  </RouterNavLink>
                </NavItem>
                <AuthorizationGuard scopes={['nxp-sso:token-tester']}>
                  <NavItem>
                    <FontAwesomeIcon icon="key" className="mr-3"/>
                    <RouterNavLink
                      to="#"
                      onClick={() => setIsTokenTesterOpen(true)}
                      activeClassName="router-link-exact-active"
                    >
                    Token Tester
                    </RouterNavLink>
                  </NavItem>
                </AuthorizationGuard>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3"/>
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavBar
