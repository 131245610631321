import { useCallback, useEffect, useState } from "react"
import { useIsMounted } from "./useIsMounted"
import { callApi } from '../api'
import { useAccessToken } from "./useAccessToken"

export const useApiCallback = endpoint => {
  const [{ data, error, loading }, setApiResult] = useState({ })
  const isMounted = useIsMounted()
  const { accessToken, isAuthenticated } = useAccessToken()

  const call = useCallback(async endpoint => {
    if(data || loading || !accessToken || !isAuthenticated) {
      return
    }

    const apiResult = await callApi(endpoint, accessToken)
    if(isMounted){
      setApiResult({
        ...apiResult
      })
    }
  }, [accessToken, data, isAuthenticated, isMounted, loading])

  useEffect(() => {
    call(endpoint)
  }, [call, endpoint])

  return { call, data, error, loading }
}
