import PropTypes from "prop-types"
import { useAvailableTokenScopes } from '../hooks/useAvailableTokenScopes'

export const AuthorizationGuard = ({ scopes, children }) => {
  const availableScopes = useAvailableTokenScopes()

  return <>
    { scopes.some(s => availableScopes.includes(s)) && children }
  </>
}

AuthorizationGuard.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element
}
