import { useMemo } from "react"

export const useUserApiRoutes = metadata => {
  const endpoints = (metadata || {}).endpoints || []

  const routes = endpoints.map(endpoint => {
    const name = endpoint.path.replace('/', '').replaceAll('/', ' ')
    return {
      path: endpoint.path,
      name: name.charAt(0).toUpperCase() + name.slice(1)
    }
  })

  return useMemo(() => ({ routes }), [routes])
}
