import { useAuth0 } from "@auth0/auth0-react"
import { useCallback, useEffect, useState } from "react"

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(null)
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const getAccessToken = useCallback(async () => {
    let token = null
    if(isAuthenticated){
      token = await getAccessTokenSilently()
    }
    setAccessToken(token)
  }, [getAccessTokenSilently, isAuthenticated])

  useEffect(() => {
    getAccessToken()
  }, [getAccessToken])

  return { accessToken, isAuthenticated, getAccessToken }
}
