import { useMemo } from 'react'
import { useAccessToken } from './useAccessToken'
import jwt_decode from 'jwt-decode'

export const useAvailableTokenScopes = () => {
  const { accessToken } = useAccessToken()

  return useMemo(() => {
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken)
      const scope = decodedToken.scope || ''
      return scope.split(' ').filter(s => s)
    }
    return []
  }, [accessToken])
}
