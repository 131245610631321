import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TokenDetails from './TokenDetails'

import styles from './TokenTester.module.scss'
import PropTypes from "prop-types"

const TokenTester = ({ isOpen, onClose }) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="lg"
        keepMounted={true}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>NXP SSO Token Tester</DialogTitle>
        <DialogContent>
          <TokenDetails/>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button variant="contained" onClick={onClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

TokenTester.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export {
  TokenTester
}
