import React, { useContext } from 'react'
import PropTypes from "prop-types"
import { useUserApiRoutes } from "../hooks/useUserApiRoutes"
import { useAppMetadata } from "../hooks/useAppMetadata"
import { useAuth0 } from "@auth0/auth0-react"

const AppContext = React.createContext({})

export const AppContextProvider = ({ children }) => {
  const { metadata } = useAppMetadata()
  const { error, isLoading } = useAuth0()
  const { routes } = useUserApiRoutes(metadata)
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const isUserLoggedIn = !isAuthenticated && !isLoading
  const isMetadataInitialized = !!metadata
  const isInitialized = isUserLoggedIn || isMetadataInitialized

  return <AppContext.Provider value={{
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    metadata,
    routes,
    isInitialized,
    error
  }}>
    {children}
  </AppContext.Provider>
}

AppContextProvider.propTypes = {
  children: PropTypes.element
}

export const useAppContext = () => useContext(AppContext)
