import Highlight from "../components/Highlight"
import Loading from "../components/Loading"
import PropTypes from "prop-types"
import { useApiCallback } from "../hooks/useApiCallback"

const BackendData = ({ apiPath }) => {
  const { data } = useApiCallback(apiPath)

  if(!data) {
    return <div>
      <Loading className={'spinner-small'}/>
    </div>
  }

  return <Highlight>
    <span>{JSON.stringify(data, null, 2)}</span>
  </Highlight>
}

BackendData.propTypes = {
  apiPath: PropTypes.string
}

export default BackendData
