const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src="./logo-192x192.png" alt="NXP-SSO" width="120" />
    <h1 className="mb-4">NXP-SSO integration sample app</h1>

    <p className="lead" style={{ marginBottom: '0px' }}>
      This sample application demonstrates Auth0 authentication flow for
      React SPA that uses its own api with Role Based Access Control via access tokens.
      <br/>
      Application uses 2 custom api scopes(you can assign permissions for you user in Auth0 console):
      <strong>sample-app:admin</strong> and <strong>sample-app:user</strong>.
      <br/>
      For singup/profilе app uses custom Auth0-based app (<strong>NXP-SSO</strong>)
    </p>
  </div>
)

export default Hero
