import { styled } from "@mui/material/styles"
import { Tooltip, tooltipClasses } from "@mui/material"

export const TokenTesterTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey.A100,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: 500
  }
}))
