import { Fragment } from "react"

const VerifyEmail = () => (
  <Fragment>
    <hr/>
    You need to verify your email first. Please check your inbox.
  </Fragment>
)

export default VerifyEmail
