import { Container, Row, Col, Input } from "reactstrap"
import { useState } from "react"
import axios from 'axios'
import Highlight from "../components/Highlight"
import Loading from "../components/Loading"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import countries from "../utils/countries"
import profileDataConfig from "../utils/profileDataConfig"
import { getConfig } from "../config"

export const ProfileComponent = () => {

  const { domain, management_audience } = getConfig()
  const { user, getAccessTokenSilently } = useAuth0()
  const [userMetadata, setUserMetadata] = useState(user.user_metadata)
  const [validatingMessage, setValidatingMessage] = useState("")
  const [responseErrorMessage, setResponseErrorMessage] = useState("")
  const [userEditMetadata, setUserEditMetadata] = useState(user.user_metadata)
  const createOptions = (domain, userId, token) => {
    return {
      method: 'PATCH',
      url: `https://${domain}/api/v2/users/${userId}`,
      headers: { authorization: `Bearer ${token}`, 'content-type': 'application/json' },
      data: { user_metadata: userEditMetadata }
    }
  }

  const handleEdit = (name, value) => {
    setUserEditMetadata({
      ...userEditMetadata,
      [name]: value
    })
  }

  const isValidMetadata = () => {
    const emptyFields = []
    profileDataConfig.filter(profileDataItem => profileDataItem.isRequired).map(profileDataItem => {
      const value = userEditMetadata[profileDataItem.key]
      if(value == "" || value == undefined || value?.length == 0) {
        emptyFields.push(profileDataItem.displayName)
      }
    })
    if (emptyFields.length > 0) {
      setValidatingMessage(`Some required fields are empty: ${emptyFields}`)
      return false
    }else {
      setValidatingMessage("")
      return true
    }
  }

  const handleUpdate = async () => {
    if (isValidMetadata()) {
      try {
        const token = await getAccessTokenSilently({ ignoreCache: true, audience: management_audience })
        const response = await axios.request(createOptions(domain, user.sub, token))
        setUserMetadata(response.data.user_metadata)
        setResponseErrorMessage("")
      } catch (error) {
        setResponseErrorMessage(error.message)
      }
    }
  }

  const renderOptions = () => countries.map(
    ({ name }) => (
      <option key={name} value={name}>{name}</option>
    ),
  )

  const renderForm = () => profileDataConfig.map(
    (profileData) => (
      <Row key={profileData.key} className="align-items-center profile-header mb-12 text-center text-md-left">
        <Col md={2}>
          <span>{profileData.displayName}</span>
        </Col>
        <Col md={6}>
          { profileData.isDropdown
            ? (<Input
              data-testid={`input-${profileData.key}`}
              type={"select"}
              value={userEditMetadata[profileData.key]}
              onChange={(e) => handleEdit(profileData.key, e.target.value)}
            >
              <option value=""> no value</option>
              {renderOptions()}
            </Input>)
            : (<Input
              data-testid={`input-${profileData.key}`}
              key={profileData.key}
              name={profileData.key}
              onChange={(e) => handleEdit(profileData.key, e.target.value)}
              value={userEditMetadata[profileData.key]} />) }
        </Col>
      </Row>
    ),
  )

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>

        <Highlight>{JSON.stringify(userMetadata, null, 2)}</Highlight>
      </Row>
      {renderForm()}
      <button data-testid="update-button" onClick={handleUpdate}> update </button>
      <p data-testid="validating-message" style={{ color: 'red' }}>{validatingMessage}</p>
      <p data-testid="response-error-message" style={{ color: 'red' }}>{responseErrorMessage}</p>
    </Container>
  )
}

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />
})
